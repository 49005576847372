<template>
  <div class="new">
    <h2>最新音乐</h2>
    <ul>
      <reuse-sheet v-for="item in playlists" :key="item.id" :item="item">
        <img :src="item.coverImgUrl" />
        <p>{{ item.name }}</p>
      </reuse-sheet>
    </ul>
    <h1>暂无更多歌单</h1>
  </div>
</template>
<script>
import ReuseSheet from "@/components/ReuseSheet";
export default {
  data() {
    return {
      playlists: [],
    };
  },
  components: {
    ReuseSheet,
  },
  created() {
    this.$axios.get("/top/playlist?limit=15&order=new").then((d) => {
      this.playlists = d.data.playlists;
      console.log(d);
    });
  },
};
</script>
<style lang="less" scoped>
.new {
  h2 {
    display: inline-block;
    font-size: 15px;
    // font-weight: bolder;
    margin-bottom: 10px;
    color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 10px 0 10px;
    background-color: #485c84;
  }
  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      width: 32%;
      position: relative;
    }
  }
}
</style>